<template>
  <div class="home">
    <ul>
      <li v-for="item,index in arr" :key="item">
        <p>{{ item }}</p>
        <div class="del" @click="hdclick(index)">删除</div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState ,mapMutations} from "vuex";
export default {
  name: "Home",
  data(){
    return {
    }
  },
  components: {
    // HelloWorld
  },
  computed: {
    ...mapState({
      arr: (state) => state.num.arr,
    }),
  },
  methods:{
    ...mapMutations({
 shanchu: "num/shanchu",
    }),
    hdclick(i){
         this.shanchu(i)
      }
  }
};
</script>
<style lang = "less" scoped>
.home {
  background-color: whitesmoke;
  height:550px;
  overflow: auto;
  .del{
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #ccc;
    color:white;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
  }
  p {
    height: 70px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    overflow: hidden;
  }
}
ul {
  height: 100%;
  /* overflow: auto;
  position: relative;
  z-index: -1; */
  li {
    position: relative;
    height: 130px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
}
.active{
  background-color: red;
   height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color:white;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
}
</style>
