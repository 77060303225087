<template>
  <div id="app">
  <Header></Header>
   <Footer></Footer>
    <router-view/>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
export default{
  
  components:{
    Footer,Header,
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
}
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
