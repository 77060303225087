<template>
  <div>
    <div class="head">
      <div class="l">
        <img src="../assets/内嵌比对.png" alt="" width="40px" height="40px" />
      </div>
      <div class="c">
        <input type="text" placeholder="搜索你的内容..." v-model="text" />
      </div>
      <div class="r" @click="search()">
        <img src="../assets/搜索.png" alt="" width="20px" height="20px" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      text: "",
    };
  },
  created() {
    if (JSON.parse(localStorage.getItem("arr")) == null) {
      localStorage.setItem("arr", JSON.stringify(this.arr));
    }
    this.chanarr(JSON.parse(localStorage.getItem("arr")));
    //  console.log(JSON.parse(localStorage.getItem("arr")));
  },
  computed: {
    ...mapState({
      arr: (state) => state.num.arr,
    }),
  },
  watch: {
    arr: {
      handler() {
        localStorage.setItem("arr", JSON.stringify(this.arr));
      },
    },
  },
  methods: {
    ...mapMutations({
      chanarr: "num/chanarr",
    }),
    //   search(){
    //       this.arr.forEach(item=>{
    //           if(item.includes(this.text)){

    //           }
    //       })
    //   }
  },
};
</script>
 
<style lang = "less" scoped>
.head {
  height: 2.5rem;
  background-color: cornflowerblue;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .l {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fff;
  }
  .r {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c {
    flex: 1;
    input {
      height: 35px;
      width: 90%;
      float: right;
      margin-right: 4%;
      outline: none;
      box-sizing: border-box;
      border: 1px solid #ccc;
      text-indent: 2em;
      border-radius: 1.25rem;
    }
  }
}
</style>