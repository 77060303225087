export default {
    namespaced:true,
    state: {
        arr: [
                "皱巴巴的生活需要适时的熨烫一下",
                "平淡日子里泛着光",
        ]
  },
    mutations: {
       tianjia(state,payload) { 
            state.arr.unshift(payload)
        },
        shanchu(state,index) { 
            state.arr.splice(index,1)
        },
        chanarr(state,payload) { 
            state.arr=payload
        }
  },
  actions: {
  },
}