<template>
  <div>
    <div class="footer">
      <ul>
        <li><img src="../assets/首页.png" alt="" height="30px" width="30px" @click="$router.push('/home')"></li>
        <li>
           <div class="fabu" @click="$router.push('/Input')">
            <img src="../assets/加.png" alt="" width="20px" height="20px">
        </div>
        </li>
        <li><img src="../assets/我的.png" alt=""  height="30px" width="30px" @click="$router.push('/about')"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

 
    }
  }
}
</script>
 
<style lang = "less" scoped>
.footer{
  width: 100%;
  position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 9;
}
  ul{
    background-color: whitesmoke;
    display: flex;
    justify-content:space-around;
    align-items: center;
    height: 3.125rem;
    width: 100%;
    li{
      text-align: center;
    }
  }
  .fabu{
        height: 2.5rem;
        width: 5rem;
        border: 3px solid cornflowerblue;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>